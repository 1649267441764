@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@400;500&display=swap');

body {
  margin: 0;
}

* {
  font-family: "DM Mono", monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/** TEMPLATE **/
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 780px;
  margin: 0 auto;
  padding: 16px 15px 8px;
}
.page-header h1,
.page-header h1 a {
  color: #000;
}
body[data-theme="dark"] .page-header h1,
body[data-theme="dark"] .page-header h1 a {
  color: #fff;
}
.page-header--right {
  display: flex;
  flex-align: inline-flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}
.page-content {
  width: 100%;
  max-width: 780px;
  margin: 0 auto;
  padding: 8px 15px 64px;
}

/** GALLERY **/
.nft-gallery .nft-card {
  margin: 20px 0;
}
.nft-gallery--filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 8px 0;
  font-size: 16px;
}
.nft-gallery--filters--tags {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0 12px;
}
.nft-gallery--filters--sorting {
  white-space: nowrap;
}

/** NFT CARD **/
.nft-card {
  text-align: left;
  border: 1px solid #cccccc;
  border-radius: 2px;
}
body[data-theme="dark"] .nft-card {
  border-color: #424242;
}
.nft-card--media img {
  object-fit: contain;
  width: 100%;
  height: 550px;
  background-color: #f6f6f6;
}
.nft-card--media--loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 550px;
  background-color: #f6f6f6;
}
body[data-theme="dark"] .nft-card--media img, body[data-theme="dark"] .nft-card--media--loading {
  background-color: rgba(255, 255, 255, 0.04);
}
.nft-card--loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 580px;
  padding: 40px 20px;
  text-align: center;
  color: #999;
}
.nft-card--load-error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 550px;
  text-align: center;
  color: #999;
}
.nft-card--reactions {
  text-align: center;
}
.nft-card--reactions--scores {
  display: inline-flex;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 12px;
  border-radius: 64px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 6px 26px rgba(0, 0, 0, 0.1);
}
body[data-theme="dark"] .nft-card--reactions--scores {
  box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.05), 0px 6px 26px rgba(255, 255, 255, 0.1);
}
.nft-card--reactions--add {
  padding: 4px 0;
  font-size: 24px;
  width: 36px;
  cursor: pointer;
  line-height: 1em;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 36px;
}
body[data-theme="dark"] .nft-card--reactions--add {
  background-color: rgba(255, 255, 255, 0.06);
}
.nft-reaction {
  font-size: 16px;
}
.nft-reaction img {
  width: 36px;
  height: 36px;
  object-fit: contain;
}
.nft-reactions--react p {
  text-align: center;
}
.nft-reactions--react--option {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.nft-reactions--react--option--buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.nft-pagination {
  display: inline-flex;
  gap: 16px;
}
.nft-card--info {
  padding: 12px 24px;
  font-size: 12px;
}
.nft-card--details {
  padding: 12px 24px;
  font-size: 12px;
}
.nft-card--details h3 {
  font-size: 16px;
}
.nft-card--details h3,
.nft-card--details a {
  color: #000;
}
body[data-theme="dark"] .nft-card--details h3,
body[data-theme="dark"] .nft-card--details a {
  color: #fff;
}

.nft-submit--preview .nft-card--media img,
.nft-submit--preview .nft-card--media--loading {
  height: 350px;
}
.nft-card--info--submitter {
  display: inline-flex;
  align-items: center;
  gap: 6px;
}


.profile--info--avatar canvas {
  border-radius: 400px;
  overflow: hidden;
}
.profile--reactions {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.profile--reactions .nft-reaction {
  padding: 4px 10px;
  border-radius: 36px;
  border: 1px solid #ccc;
}
body[data-theme="dark"] .profile--reactions .nft-reaction {
  border-color: #424242;
}


.address {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}
.address canvas {
  border-radius: 400px;
  overflow: hidden;
}


.header--account {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  padding: 6px 12px;
  border-radius: 100px;
  border: 1px solid #ccc;
  vertical-align: middle;
}
body[data-theme="dark"] .header--account {
  border-color: #424242;
}
